import * as styles from "./LinkButton.module.scss";
const LinkButton = ({
  item, color, handleLinkClick, theme
}) => {
  const handleClick = (clickItem) => {
    if (clickItem.link) {
      window.open(clickItem.link, "_blank");
    } else {
      handleLinkClick();
    }
  };
  return (
    <div className={`${styles.linkbottom} ${theme === 'black' ? styles.blackButtom : styles.whiteButtom} 2xl:group-hover:opacity-100 inline-block 2xl:opacity-100 transition-opacity duration-500 ease-in-out mr-4 md:mr-[56px] xl:mr-[56px] 2xl:mr-[56px] mb-4`}>
      <div onClick={() => handleClick(item)} className="inline-block group-button cursor-pointer">
        <span className={`${styles.myBottom} flex items-center relative hover:bg-[#000] hover:bg-gradient-to-r`}>
          <span className={`${styles.bottomText} mr-[12px] text-[14px] xl:text-[16px] whitespace-nowrap transition-all duration-300 group-button-hover:pl-[7px] group-button-hover:md:pl-[8px] group-button-hover:xl:pl-[10px] group-button-hover:mr-[10px] group-button-hover:text-white`}>{item.text}</span>
          <span
            // className="p-[5px] md:p-[7px] xl:p-[10px] transition-all duration-300 border-[3px] xl:border-[4px] border-white group-button-hover:pl-0 group-button-hover:border-transparent box-border"
            className={`${styles.bottomBorder} p-[5px] md:p-[7px] xl:p-[10px] transition-all duration-300 border-[3px] xl:border-[4px] group-button-hover:pl-0 group-button-hover:border-transparent box-border`}
          >
            <svg
              viewBox="0 0 18 18"
              fill={theme === 'black' ? '#000' : "#fff"}
              className="h-4 w-4 md:h-5 md:w-5 group-button-hover:fill-white"
            >
              <rect
                x="4.08447"
                y="0.750244"
                width="13.3333"
                height="3.33333"
              // fill={color ? "black" : "white"}
              // className={color ? "group-button-hover:fill-black" : "group-button-hover:fill-white"}
              // className="group-button-hover:text-white"
              />
              <rect
                x="0.751465"
                y="14.8928"
                width="20"
                height="3.33333"
                transform="rotate(-45 0.751465 14.8928)"
              // fill={color ? "black" : "white"}
              // className={color ? "group-button-hover:fill-black" : "group-button-hover:fill-white"}
              // className="group-button-hover:text-white"
              />
              <rect
                x="17.4185"
                y="0.750244"
                width="13.3333"
                height="3.33333"
                transform="rotate(90 17.4185 0.750244)"
              // fill={color ? "black" : "white"}
              // className={color ? "group-button-hover:fill-black" : "group-button-hover:fill-white"}
              // className="group-button-hover:text-white"
              />
            </svg>
          </span>
        </span>
      </div>
    </div>
  );
};

export default LinkButton;
