// import { LinkButton } from "@/components";
import { motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
// import { Button } from "antd";
// import LinkButton from "./LinkButton";
import LinkButton from "./LinkButton";

const ImageBanner = (props) => {
  const { content, color } = props;
  const { pc, mobile, title, mobiletitle, desc, buttomList } = content;
  if (!pc || !mobile) return null;
  const [imageSrc, setImageSrc] = useState('');
  const [bannerTitle, setBannerTitle] = useState(null);

  const updateImage = useCallback(() => {
    console.log('title:', title);
    const isMobile = window?.innerWidth < 768;
    // 如果是PC端，bannerTitle为title
    if (!isMobile) {
      // 将,和，替换为<br />
      // setBannerTitle(title.replace(/,|，/g, '<br />'));
      setBannerTitle(title);
    } else {
      setBannerTitle(mobiletitle);
    }
    setImageSrc(isMobile ? mobile : pc);
  }, [mobile, pc]);

  useEffect(() => {
    updateImage();
    window.addEventListener("resize", updateImage);
    return () => window.removeEventListener("resize", updateImage);
  }, [updateImage]);

  return (
    <div
      id="imageBanner"
      className={`text-white bg-black relative overflow-hidden group`}
    // className={`${styles.imageBanner} ${styles.textwhite}`}
    // className={styles.imageBanner}
    >
      {imageSrc && (
        <div style={{ overflow: 'hidden' }}>
          <img
            className="group-hover:scale-110 transition-transform duration-500 max-w-full w-full"
            // className={styles.image}
            src={imageSrc}
          // alt={imageObj.alt}
          // width={imageObj.width}
          // height={imageObj.height}
          />
        </div>
      )}
      <div
        className="m-auto absolute z-10 left-0 bottom-[10%] w-full"
      // className={styles.content}
      >
        <div
          // className="max-w-full px-[25px] md:px-[40px] xl:px-[80px] 2xl:max-w-screen-2xl 2xl:px-0 mx-auto"
          className="max-w-[1200px] px-[25px] md:px-[40px] xl:px-[0px] 2xl:px-0 mx-auto"
        // className={styles.contentInner}
        >
          {/* <div className="md:max-w-[484px]"> */}
          <div>
            {/* {customTag && (
              <motion.div
                initial={{ opacity: 0, translateY: 64 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 0.5 }}
                className="text-[12px] md:text-[14px] xl:text-[16px] 2xl:text-[20px] font-semibold uppercase rounded-[20px] w-[fit-content] py-1 px-3 "
                // className={styles.customTag}
                style={{
                  border: `1px solid ${light ? "#000" : "#fff"}`,
                }}
              >
                {customTag}
              </motion.div>
              // <div className={styles.customTag}>{customTag}</div>
            )} */}
            {title && (
              <motion.div
                initial={{ opacity: 0, translateY: 64 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 0.5 }}
                className="font-[700] text-[32px] md:text-[40px] lg:text-[40px] xl:text-[40px] 2xl:text-[40px] my-5 leading-[1.2]"
                style={{ color: color ? color : '#fff' }}
              >
                {/* {title} */}
                <div dangerouslySetInnerHTML={{ __html: bannerTitle }} ></div>
              </motion.div>
            )}
            {desc && (
              <motion.div
                initial={{ opacity: 0, translateY: 64 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 0.5 }}
                className="font-[700] mt-[16px]  text-[14px] md:text-[18px] lg:text-[18px] xl:text-[18px] 2xl:text-[18px]"
                style={{ color: color ? color : '#fff' }}
                dangerouslySetInnerHTML={{ __html: desc }}
              />
              // <div className={styles.desc}>{desc}</div>
            )}
            {/* {buttomList.map((item, index) => (
                <div key={index} className="2xl:group-hover:opacity-100 inline-block 2xl:opacity-0 transition-opacity duration-500 ease-in-out">
                  <LinkButton colorReverse href={item.link} title={item.text} fontWeight="font-bold" className="md:mt-6 mt-[12px]" />
                  <Button href={item.link} title={item.text} className="md:mt-6 mt-[12px]">{item.text}</Button>
                </div>
              ))} */}

          </div>
          {buttomList && buttomList.length && (

            <div className="flex flex-wrap w-full md:justify-start xl:justify-start 2xl:justify-start mt-[22px] md:mt-[40px]">
              {buttomList.map((item, index) =>
                <span key={index}>
                  <LinkButton item={item} color={color} theme='black' />
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageBanner;
